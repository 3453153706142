<template>
  <div class="company-honor-css-all">
    <base-left-title title="企业荣誉"/>
    <div class="company-honor-css" :style="`height:${swiperHeight}`">
      <swiper
        :slidesPerView="swiperRowViews"
        :grid="{ rows: swiperRows}"
        :spaceBetween="30"
        :pagination="{clickable: true}"
        :modules="modules"
        navigation
        class="animated bounceInLeft"
        :style="`height:${swiperHeight}`"
      >
        <swiper-slide v-for="honorVo in honorVoList" :key="honorVo.id">
          <div>
            <img v-lazy="honorVo.filePath" :alt="honorVo.honorName">
            <p>{{ honorVo.honorName }}</p>
          </div>
        </swiper-slide>
      </swiper>
    </div>
  </div>
</template>

<script setup>
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/grid'
import { Navigation, Grid } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/vue'
import { getHonorList } from '/composables/api/honor'

const props = defineProps({
  swiperRows: {
    type: Number,
    default: 2
  },
  swiperRowViews: {
    type: Number,
    default: 3
  },
  swiperHeight: {
    type: String,
    default: "700px"
  }
})

const honorVoList = ref([])

const fetchHonorList = async () => {
  try {
    const response = await getHonorList()
    honorVoList.value = response.data
  } catch (error) {
    console.error('获取荣誉列表失败', error)
  }
}

onMounted(fetchHonorList)

const modules = [Grid, Navigation]
</script>

<style scoped lang="less">
.company-honor-css-all {
  padding-top: 10px;
  width: 100%;

  .company-honor-css {
    margin-left: 10vh;
    margin-right: 10vh;

    .swiper {
      width: 100%;
      --swiper-navigation-color: #000;
      --swiper-navigation-size: 25px;
    }

    .swiper-slide {
      div {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;


        img {
          height: 90%;
        }

        p {
          height: 20%;
          font-size: 12px;
          color: #000;
        }
      }
    }

  }
}
</style>
