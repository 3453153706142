<template>
  <div style="width: 100%">
    <base-left-title title="知识产权"/>
    <div class="company-property-css" :style="`height:${swiperHeight}`">
      <swiper
        :slidesPerView="swiperRowViews"
        :grid="{ rows: swiperRows}"
        :spaceBetween="30"
        :pagination="{clickable: true}"
        :modules="modules"
        navigation
        class="animated zoomInDown"
        :style="`height:${swiperHeight}`"
      >
        <swiper-slide v-for="propertyVo in propertyVoList" :key="propertyVo.id">
          <div>
            <img v-lazy="propertyVo.filePath" :alt="propertyVo.propertyName">
            <p>{{ propertyVo.propertyName }}</p>
          </div>
        </swiper-slide>
      </swiper>
    </div>
  </div>
</template>

<script setup>
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/grid'
import { Navigation, Grid } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/vue'
import { getPropertyList } from "/composables/api/property"

const props = defineProps({
  swiperRows: {
    type: Number,
    default: 2
  },
  swiperRowViews: {
    type: Number,
    default: 3
  },
  swiperHeight: {
    type: String,
    default: "700px"
  }
})

const propertyVoList = ref([])

const fetchPropertyList = async () => {
  try {
    const response = await getPropertyList()
    propertyVoList.value = response.data
  } catch (error) {
    console.error('获取知识产权列表失败', error)
  }
}

onMounted(fetchPropertyList)

const modules = [Grid, Navigation]
</script>

<style scoped lang="less">
.company-property-css {
  margin-left: 10vh;
  margin-right: 10vh;

  .swiper {
    width: 100%;
    --swiper-navigation-color: #000;
    --swiper-navigation-size: 25px;
  }

  .swiper-slide {
    div {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      img {
        height: 90%;
      }

      p {
        height: 20%;
        font-size: 12px;
        color: #000;
      }
    }
  }
}
</style>
